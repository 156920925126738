import React from "react"
import Header from "../components/layout/header"
import Layout from "../components/layout/layout"
import Particles from "react-particles-js"

export default () => (
  <Layout>
    <Header title="Seun.io" />
    <Particles
      params={{
        particles: {
          number: {
            value: 50,
            density: {
              enable: true,
              value_area: 2000,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 4,
            },
            image: {
              src: "img/github.svg",
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.5,
            random: true,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 5,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "bounce",
            attract: {
              enable: true,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "repulse",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
      }}
      style={{
        width: "100%",
        zIndex: "-100",
        position: "fixed",
      }}
    />
    <main id="home">
      <h1 className="lg-heading">Seun </h1>
      {/* <div className="divider" /> */}
      <h2 className="md-heading">
        <span>Full Stack Web Developer</span>
      </h2>
      {/* <p>Based in Boston</p> */}
      <div class="socials">
        <a target="_blank" href="https://github.com/Seun-O">
          <i class="fab fa-github-alt" />
        </a>
        <a target="_blank" href="https://www.linkedin.com/in/seun-olorunwunmi/">
          <i class="fab fa-linkedin-in" />
        </a>
        <a target="_blank" href="https://twitter.com/seuno_">
          <i class="fab fa-twitter" />
        </a>
        <a target="_blank" href="mailto:seuns267@gmail.com">
          <i class="fas fa-envelope" />
        </a>
      </div>
    </main>
  </Layout>
)
